import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Modal, Spinner, Image, Row, Navbar } from 'react-bootstrap';
import { Files, FileText } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import APICongressionalBillPull from './APICongressionalBillPull';
import APIMemberVotePull from './APIMemberVotePull';

import MemberVotes from './MemberVotes';
import MemberVotesFull from './MemberVotesFull';
import MobileOffCanvas from './MobileOffCanvas';
import SponsoredBills from './SponsoredBills';

var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

function RepresenativesPage(props) {
  const [memberData, setMemberData] = useState(null);
  const bio_guide_id = props.match.params.bio_guide_id;
  const name = props.location.state.name;
  const party = props.location.state.party;
  const address = props.location.state.address;
  const webURL =  props.location.state.webURL
  const imageSource = props.location.state.imageSource
  const chamber = props.location.state.chamber
  const startYear = props.location.state.startYear
  const stateName = props.location.state.stateName
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [bioGuideId, setBioGuideId] = useState(null);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileText, setProfileText] = useState('');
  var [feedLength, setFeedLength] = useState(20);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    return `${month}-${day}-${year}`;
  }

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }

  require('dotenv').config();
  const apiKey = process.env.REACT_APP_API_KEY;
  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer "+apiKey,
    },
  }
  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching data
  
        const url = `https://api.govglance.org/members/{bio_guid_id}?bio_guide_id=${bio_guide_id}&limit=${feedLength}&skip=0`;
        const response = await fetch(url, requestOptions);
  
        if (!response.ok) {
          throw new Error('Failed to fetch member data');
        }
  
        const data = await response.json();
        setMemberData(data);
        setLoading(false); // Set loading to false after data fetching is completed
      } catch (error) {
        console.error('Error fetching member data:', error);
        setError(error);
        setLoading(false); // Set loading to false in case of an error
      }
    };
  
    fetchMemberData();
  }, [bio_guide_id]); 

 
  // useEffect(() => {
  //   const requestOptions2 = {
  //     method: 'GET',
  //     headers: { 
  //       'Content-Type': 'application/json',
  //       "Authorization": "Bearer "+ "9k2ZNeJbBneoFkRgb8pDUpzgK1lCkzCRNe2qiL2U",
  //     },
  //   }
  //   const fetchProfileText = async () => {
  //     try {
  //       setLoading(true);
  
  //       const url = `https://bioguide.congress.gov/search/bio/${bio_guide_id}.json`;
  //       const response = await fetch(url);
  
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch member data');
  //       }
  
  //       const data = await response.xml();
  //       console.log('bio: ')
  // console.log(data)
  //       if (data && data.data && data.data.profileText) {
  //         // Extract profile text
  //         const profileText = data.data.profileText;
  //         // Update state with profileText
  //         setProfileText(profileText);
  //       }
  
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching profile text:', error);
  //       setError(error);
  //       setLoading(false);
  //     }
  //   };
  
  //   fetchProfileText();
  // }, [bio_guide_id]);

  const handleViewMoreClick = (bioGuideId, firstName, lastName) => {
    setBioGuideId(bioGuideId);
    setFirstName(firstName)
    setLastName(lastName)
    setShowModal(true);
  
  }

  const handleViewMoreClick2 = (bioGuideId, firstName, lastName) => {
    setBioGuideId(bioGuideId);
    setFirstName(firstName)
    setLastName(lastName)
    setShowModal2(true);
  
  }
  const renderData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }
  
    if (!memberData) {
      return null; // or any placeholder message if needed
    }

  
    return memberData.map((item, index) => {
      return (
        <div key={index}>
          {item && (
            <>
              <Container id='feedContainerNormalView'>
                <p color='white'>{item.role} - <APICongressionalBillPull congressionalBillId={item.id}/> </p>
              </Container>
              <br></br>
            </>
          )}
        </div>
      
      );
      
    });
  }

  const renderVotesData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }
  
    if (!memberData) {
      return null; // or any placeholder message if needed
    }

  
    return memberData.map((item, index) => {
      return (
        <div key={index}>
          {item && (
            <>
              <Container id='feedContainerNormalView'>
                <p color='white'><APICongressionalBillPull congressionalBillId={item.id}/> </p>
              </Container>
              <br></br>
            </>
          )}
        </div>
      
      );
      
    });
  }
      
    
      return (
        <>
        <BrowserView>
   
<div id='homebg'>
        <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>


                    <div style={{ position: 'relative' }}>
  <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
    <Image id='profileImage-image' src={imageSource} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
  </div>

  <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
    <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
      <span>{name}</span>
    </div>
    <div style={{ color: 'white', fontSize: '16px' }}>
      <span>{stateName}  {chamber} - {startYear}</span>
      <br />
    </div>
    <div style={{ color: 'white', fontSize: '18px' }}>
      <span>{address.officeAddress}, {address.city} {address.district}</span>
      <br />
      <span>{address.phoneNumber}</span>
      <br></br>
      {webURL && (
        <Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open(webURL, "_blank");
        }}>
          {webURL}
        </Link>
      )}
    </div>
  </div>
</div>
       

   



            
            
<br></br><br></br><br></br><br></br>

<Card.Body  id='cardBodyRep' onClick={() => handleViewMoreClick(bio_guide_id, name )}><h3>Sponsored Bills </h3></Card.Body>
{renderData()}
<Button id='whDocsViewMoreButton' onClick={() => handleViewMoreClick(bio_guide_id, name )}>  <Files size={20}></Files> View More</Button>
{/* 
onClick={() => handleViewMoreClick2(bio_guide_id, name )} */}
  <Card.Body  id='cardBodyRep' ><h3>Votes</h3></Card.Body>
<APIMemberVotePull bio_guide_id={bio_guide_id}/>
          

          <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name} Sponsored Bills</h3></Modal.Header>
  
  
      
            <SponsoredBills bioGuideID={bioGuideId}/>
  
        </Modal>

        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal2} onHide={() => setShowModal2(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name} Votes</h3></Modal.Header>
  
  
      
            <MemberVotesFull bioGuideID={bioGuideId}/>
  
        </Modal>
          </div>
          </div>
          {/* <div id='fullBillFeed'>
          <Button id='standardButton' onClick={() => {
                 setFeedLength(feedLength+=20)
                }}>More</Button>
                  </div> */}
                  </BrowserView>

                  <MobileView>
                    <div id='homebg'>
                  <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>
          
                    <br></br>
                  
                    <div style={{ position: 'relative' }}>
  <div id='profileImage' style={{ width: '70px', height: '70px', borderRadius: '100%', overflow: 'hidden' }}>
    <Image id='profileImage-image' src={imageSource} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
  </div>

  <div style={{ position: 'absolute', top: '0', left: '100px', display: 'flex', flexDirection: 'column' }}>
    <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
      <span>{name}</span>
    </div>
    <div style={{ color: 'white', fontSize: '16px' }}>
      <span>{stateName}  {chamber} - {startYear}</span>
      <br />
    </div>
    <div style={{ color: 'white', fontSize: '12px' }}>
      <span>{address.officeAddress}, {address.city} {address.district}</span>
      <br />
      <span>{address.phoneNumber}</span>
      <br></br>
      {webURL && (
        <Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open(webURL, "_blank");
        }}>
          {webURL}
        </Link>
      )}
    </div>
  </div>
</div>
       

   



            
            
<br></br><br></br><br></br><br></br>
<Card.Body id='billBodyText' onClick={() => handleViewMoreClick(bio_guide_id, name )}><h3>Sponsored Bills </h3></Card.Body>
{renderData()}
<Button id='whDocsViewMoreButton' onClick={() => handleViewMoreClick(bio_guide_id, name )}>  <Files size={20}></Files> View More</Button>

<Card.Body id='billBodyText' ><h3>Votes </h3></Card.Body>
<APIMemberVotePull bio_guide_id={bio_guide_id}/>

           <br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
    
            </div>

</Navbar>
          <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name} Sponsored Bills</h3></Modal.Header>
  
  
      
            <SponsoredBills bioGuideID={bioGuideId}/>
  
        </Modal>
          </div>
          </div>



                  </MobileView>
                  </>
                  )
                }
                

export default RepresenativesPage;
